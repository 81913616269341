import gql from 'graphql-tag'

export const oauthAccounts = gql`query oauthAccounts {
  accounts: auth_useroauth {
    id
    provider
    providerUserId
  }
}`

export const discordAuthUrl = gql`query discordAuthUrl {
  auth: discordAuthUrl {
    url
    state
  }
}`

export const discordAuthConnect = gql`mutation discordAuthConnect ($code: String!) {
  status: discordAuthConnect(code: $code) {
    success
  }
}`
