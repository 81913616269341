<template>
  <section aria-labelledby="quick-links-title">
    <div class="p-6">
      <div class="md:flex md:items-center md:justify-between">
        <h2 class="text-xl font-bold text-gray-900 sm:text-2xl">Discord</h2>
      </div>
      <p class="mt-2 text-sm text-gray-500">
        Connect your Discord account to enter giveaways & competitions with community requirements.
      </p>
      <div class="mt-5">
        <div v-if="discordAccount" class="w-72 bg-green-50 border-l-4 border-green-400 p-4">
          <div class="flex">
            <div class="flex-shrink-0">
              <CheckIcon class="h-5 w-5 text-green-400" aria-hidden="true" />
            </div>
            <div class="ml-3">
              <p class="text-sm text-green-700">
                Account connected
              </p>
            </div>
          </div>
        </div>
        <DBtn v-else primary color="blue" icon="discord" :loading="loading" @click="startConnection">
          Connect Discord
        </DBtn>
      </div>
    </div>
  </section>
</template>

<script setup>
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { oauthAccounts, discordAuthUrl, discordAuthConnect } from '@/gql/oauth'
import { useQuery, useResult, useMutation } from '@vue/apollo-composable'
import { CheckIcon } from '@heroicons/vue/outline'
import { DBtn } from '@ui'

const route = useRoute()

// Query existing accounts to show connection state
const { result: accounts, loading: accountsLoading, refetch } = useQuery(oauthAccounts)
const discordAccount = useResult(accounts, null, ({ accounts }) => 
  accounts.find(({ provider }) => provider === 'DISCORD')
)

// Get Oauth URL for a new connection
const { result: authUrlResult, loading: urlLoading } = useQuery(discordAuthUrl)
const auth = useResult(authUrlResult, null, ({ auth }) => auth)

// Complete the connection (called automatically if query param provided)
const { mutate: connect, loading: completeLoading } = useMutation(discordAuthConnect)

// Combined loading state
const loading = computed(() => accountsLoading.value || urlLoading.value || completeLoading.value)

// Redirect to begin oauth
const startConnection = () => {
  window.location.href = auth.value.url
}

// Complete the connection if code in URL params
const completeConnection = async () => {
  await connect({ code: route.query.code })
  await refetch()
}

if (route.query.code) {
  completeConnection()
}
</script>